import React from "react"
import { PreStyledComponents } from "@life-without-barriers/react-components"
import styled, { keyframes } from "styled-components"

import SelfAssessmentContainer from "../../../../components/foster-care/SelfAssessment/Container"
import { FosterCareForm } from "@life-without-barriers/shared-contact-form"

const { BackgroundHex } = PreStyledComponents

const slideUpAndFadeIn = keyframes`
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`

const AnimatedTitle = styled.h2`
  opacity: 0;
  animation: forwards 0.8s ease-in-out 0.1s ${slideUpAndFadeIn};
`

const AnimatedMessage = styled.p`
  opacity: 0;
  animation: forwards 0.8s ease-in-out 0.2s ${slideUpAndFadeIn};
`

const AnimatedLeadForm = styled.div`
  opacity: 0;
  animation: forwards 0.8s ease-in-out 0.3s ${slideUpAndFadeIn};
`

interface LeadProps {
  location: { pathname: string }
}

const SelfAssessmentLeadPage = ({ location }: LeadProps) => (
  <SelfAssessmentContainer location={location}>
    <BackgroundHex className="min-h-100 overflow-y-auto bg-white">
      <AnimatedTitle className="ma0 ts-display-2 color-lwb-theme-medium tc fw8 pt4">
        Great Work!
      </AnimatedTitle>
      <AnimatedMessage className="color-lwb-theme-medium ts-display-4 tc mh4">
        Looks like you&apos;re ready to talk to a foster care specialist.
      </AnimatedMessage>
      <AnimatedLeadForm className="mh3 pv4">
        <FosterCareForm
          formId="foster-care-self-assessment"
          formSubmitText="Submit"
        />
      </AnimatedLeadForm>
    </BackgroundHex>
  </SelfAssessmentContainer>
)

export default SelfAssessmentLeadPage
